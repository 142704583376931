<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>EO</label>
                <Dropdown id="eo_id" dataKey="eo_id" v-model="forms.eo_id" :loading="loadingDropdownEO"
                    :options="dataDropdownEO" :class="{ 'p-invalid': errorAdd.eo_id }"
                    optionLabel="label" optionValue="eo_id" placeholder="Pilih EO" :filter="true"
                    :showClear="true" @filter="searchDropdownEO($event, 'add')"
                />
                <small class="p-invalid" style="color: red" v-if="errorAdd.eo_id">{{
                    errorAdd.eo_id[0]
                }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Provinsi</label>
                <Dropdown id="provinsi_id" dataKey="provinsi_id" v-model="forms.provinsi_id" :loading="loadingDropdownProvinsi"
                    :options="dataDropdownProvinsi" :class="{ 'p-invalid': errorAdd.provinsi_id }"
                    optionLabel="provinsi_name" optionValue="provinsi_id" placeholder="Pilih Provinsi"
                    :filter="true" :showClear="true" @filter="searchDropdownProvinsi($event, 'add')"
                    @change="changeKabupaten()" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.provinsi_id">{{
                    errorAdd.provinsi_id[0]
                }}</small>
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Kabupaten</label>
                <div class="field-checkbox mt-2">
                    <Checkbox id="isAllKabupaten" v-model="checked" :binary="true" :disabled="forms.provinsi_id == null" @change="this.forms.kabupaten_id = [];"/>
                    <label for="isAllKabupaten">Centang jika pilih semua Kabupaten</label>
                </div>
                <Listbox v-model="forms.kabupaten_id" :disabled="checked" dataKey="kabupaten_id" :options="dataDropdownKabupaten" :class="{ 'p-invalid': errorAdd.kabupaten_id }" :multiple="true" :filter="true" optionLabel="kabupaten_name" optionValue="kabupaten_id" listStyle="max-height:250px" style="width:15rem" @filter="searchDropdownKabupaten($event, 'add')">
                    <template #option="slotProps">
                        <div class="country-item">
                            <div>{{slotProps.option.kabupaten_name}}</div>
                        </div>
                    </template>
                </Listbox>
                <small class="p-invalid" style="color: red" v-if="errorAdd.kabupaten_id">{{
                    errorAdd.kabupaten_id[0]
                }}</small>
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Simpan" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownEO: false,
            loadingDropdownProvinsi: false,
            loadingDropdownKabupaten: false,

            // dataDropdown,
            dataDropdownEO: null,
            dataDropdownProvinsi: null,
            dataDropdownKabupaten: null,

            // addNew
            forms: {
                eo_id: null,
                provinsi_id: null,
                kabupaten_id: []
            },
            checked: false,
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownEO('');
        this.searchDropdownProvinsi('');
    },
    computed:{
        ...mapGetters(['errorAdd']),
    },
    methods: {
        // DROPDOWN
        searchDropdownEO(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.deo.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownEO = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/eo',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if (purpose == "add") {
                        this.dataDropdownEO = res.data.data;
                        this.loadingDropdownEO = false;
                    } else if (purpose == null) {
                        this.dataDropdownEO = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        searchDropdownProvinsi(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dprov.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownProvinsi = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/provinsi',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if (purpose == "add") {
                        this.dataDropdownProvinsi = res.data.data;
                        this.loadingDropdownProvinsi = false;
                    } else if (purpose == null) {
                        this.dataDropdownProvinsi = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        changeKabupaten() {
            this.checked =  false;
            this.forms.kabupaten_id = [];
            this.dataDropdownKabupaten = null;
            this.searchDropdownKabupaten('');
        },
        searchDropdownKabupaten(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.forms.provinsi_id) {

                    if (valueEdit) {
                        this.$refs.dkab.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownKabupaten = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/kabupaten',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "provinsi_id": this.forms.provinsi_id,
                        }
                    })
                        .then(res => {

                            if (purpose == "add") {
                                this.dataDropdownKabupaten = res.data.data;
                                this.loadingDropdownKabupaten = false;
                            } else if (purpose == null) {
                                this.dataDropdownKabupaten = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            if(this.forms.provinsi_id != null){
                if(this.forms.kabupaten_id.length == 0 && this.checked == false){
                    alert("Silahkan centang atau isi kabupaten yang tersedia");
                    this.loading = false;
                    return;
                }
            }

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/eo/create-area',
                data: {
                    "eo_id": this.forms.eo_id,
                    "provinsi_id" : this.forms.provinsi_id,
                    "kabupaten_id": this.forms.kabupaten_id,
                    "isAllKabupaten": this.checked
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data ? err.response.data.data : {});
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
            });
        },
        clearForms() {
            this.forms.eo_id = null;
            this.forms.provinsi_id = null;
            this.forms.kabupaten_id = [];
            this.changeKabupaten();
        },
    }
}
</script>