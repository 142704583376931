<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="eo_nama">Nama EO</label>
                <InputText id="eo_nama" v-model="forms.eo_nama" required="true" autofocus :class="{ 'p-invalid': errorAdd.eo_nama }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.eo_nama" >{{ errorAdd.eo_nama[0] }}</small >
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Simpan" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,

            // addNew
            forms: {
                eo_nama: null,
            },
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
    },
    computed:{
        ...mapGetters(['errorAdd']),
    },
    methods: {
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/eo/create',
                data: {
                    "eo_nama": this.forms.eo_nama,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
            });
        },
        clearForms() {
            this.forms.eo_nama = null;
        },
    }
}
</script>